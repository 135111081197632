import Backbone from 'lib/backbone';
import invokeCallbacks from 'app/NVTagCallbacks';
import VgsTextFieldView from 'app/views/VgsTextFieldView';
import 'lib/jquery.payment';

var $ = Backbone.$,
  _ = Backbone._;
export default VgsTextFieldView.extend({
  __name__: 'CreditCardView',
  tagName: 'label',
  className: 'at-text at-cc-number',
  vgsConfig: {
    type: 'card-number',
    validations: ['validCardNumber'],
    autoComplete: 'cc-number',
    showCardIcon: {
      top: '0',
      transform: 'translateY(0%)'
    },
    css: {
      fontSize: '.875rem',
      fontFamily: 'monospace',
      lineHeight: '1',
      backgroundColor: '#fff',
      '&::placeholder': {
        color: '#ced4da',
        fontWeight: 'bold'
      }
    }
  },
  context: function () {
    var type = (this.def.validation || this.def.valueType);
    var context = {
      name: this.name,
      labelhide: this.options.labels === 'placeholder',
      label: this.def.label || this.title,
      title: this.title,
      value: this.def.default_value || '',
      maxlength: this.def.maxlength || 19,
      minlength: this.def.minlength || 12,
      placeholder: this.def.placeholder || '•••• •••• •••• ••••',
      pattern: type ? this.attr.pattern(type) : null,
      required: this.def.required ? 'required' : '',
      vgsLoaded: this.vgs.loaded,
      vgsContainerId: this.vgs.containerId
    };

    if (context.labelhide) {
      context.placeholder = context.title;
    }

    if (this.parent.name === 'PaymentInformation' && 'required' in this.parent.def && !this.parent.def.required) {
      context.required = '';
    }

    return invokeCallbacks('alterContext', { element: this.type, context: context, def: this.def }).context;
  },
  fallbackRender: function () {
    this.$account = this.$('input[name="Account"]').payment('formatCardNumber');

    var self = this;

    // we need to make sure that you can shift+tab back into the cc field
    // so we'll only auto-focus the next field once
    self.hasMoved = false;

    this.$('input[name="Account"]').on('keyup', function () {
      if ($.payment.validateCardNumber(this.value) && this.value.length >= 17 && !self.hasMoved) { // 15 digit CC number + 2 spaces
        self.hasMoved = true;
        self.parent.subviews.ExpirationDate.focus();
      }
    });

    return this;
  },
  handleVgsStateChange: function (newState) {
    if (newState && newState.isValid && !this.hasMoved) {
      this.hasMoved = true;
      if (this.parent.subviews.ExpirationDate) {
        //autotab to cvv if user initiates autocomplete from expiration date
        if (this.parent.subviews.ExpirationDate.vgs.state.isValid && this.parent.subviews.SecurityCode) {
          this.parent.subviews.SecurityCode.focus();
        } else {
          this.parent.subviews.ExpirationDate.focus();
        }
      }
    }
  },
  val: function () {
    if (this.vgs.loaded) {
      return null;
    }

    // Remove spaces from credit card number, added by the Stripe payment library
    var val = this.$account.val().replace(/\s/g, '');
    return (val !== '') ? val : null;
  },
  setval: function (value) {
    if (this.vgs.loaded) {
      return; // can't do this
    }

    this.$account.val(value ? $.payment.formatCardNumber(value) : value).trigger('keyup');
    return this.hideOrShow();
  },
  errors: function () {
    var errors = [];
    var cardnumber, cardtype;


    function normalizeCardType(vgsCardType) {
      switch(vgsCardType) {
        // many of these have cobranded cards with Discover -- we tentatively treat them all as Discover
        case 'discover':
        case 'jcb':
        case 'unionpay':
        case 'elo':
        case 'diners-club':
        case 'dinersclub':
          return 'discover';
        // VGS classifies RuPay as Hipercard (probably an overly broad regex).
        // Some rupay ranges are discover, so treat all hipercard classifications as potentially being Discover
        case 'hipercard':
          return 'discover';
        case 'mastercard':
          return 'mastercard';
        case 'amex':
          return 'amex';
        case 'visa':
          return 'visa';
        default:
          return vgsCardType;
      }
    }

    if (this.vgs.loaded) {
      cardnumber = !this.vgs.state || this.vgs.state.isEmpty ? '' : true;
      cardtype = this.vgs.state ? normalizeCardType(this.vgs.state.cardType) : '';
    } else {
      cardnumber = this.$account.val();
      cardtype = normalizeCardType($.payment.cardType(cardnumber));
    }

    if (this.def.required && cardnumber === '') {
      errors.push(this.resources.PrimaryResources.CreditCardNumberRequired);
    } else if (cardnumber && (
      (this.vgs.loaded && !this.vgs.state.isValid) ||
      (!this.vgs.loaded && !$.payment.validateCardNumber(cardnumber)))
    ) {
      errors.push(this.resources.PrimaryResources.InvalidCreditCardNumber);
    } else if (cardtype && !_.contains(this.parent.cards, cardtype)) {
      var card = this.parent.accepted[cardtype];
      if (card) {
        errors.push(this.resources.fill(this.resources.PrimaryResources.BlankIsNotAcceptedCreditCardType, card.name));
      } else {
        errors.push(this.resources.PrimaryResources.NotAcceptedCreditCardProvider);
      }
    }

    errors = invokeCallbacks('alterErrors', {
      val: cardnumber,
      field_name: this.field_name(),
      errors: errors,
      def: this.def
    }).errors;
    this.trigger(errors.length ? 'invalid' : 'valid');
    return _.map(errors, this.toError, this);
  },
  type: 'credit_card'
});
